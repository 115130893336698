import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "onboarding-employees-with-read-confirmations-app"
    }}>{`Onboarding employees with Read Confirmations App`}</h1>
    <br />
    <p>{`In this example, we will be building a simple introduction path for new employees of our fictitious company ACME Corp.`}</p>
    <p>{`We start by adding a new Onboarding Space to Confluence`}</p>
    <Image width={'80%'} src='/public/tutorial/RCA1.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`Now in order to make configuring read confirmations easier for us,
we can create an automation rule, so we don't have to set up
each page individually.`}</p>
    <p>{`You can access rules via the space administration - read confirmations UI.`}</p>
    <Image width={'80%'} src='/public/tutorial/RCA2.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`We will limit the read confirmation widget to pages with the
label "onboarding" - whenever a page with this label is created
or this label is added to an existing page, the read confirmation
button will be visible on the page.`}</p>
    <p>{`In this example, we will limit the audience to a group
of new employees. This is optional - it ensures that only members
of this group can confirm reading the page.`}</p>
    <Image width={'80%'} src='/public/tutorial/RCA3.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`Now, when a page with the label "onboarding" is created, the read confirmation
button is displayed at the bottom of the page.
You can customize the location by adding the `}<a parentName="p" {...{
        "href": "/readconfirm-macro"
      }}>{`macro`}</a>{` anywhere on the page.`}</p>
    <Image width={'80%'} src='/public/tutorial/RCA4.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`Now, a list of read confirmations is accessible`}</p>
    <Image width={'80%'} src='/public/tutorial/RCA5.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      